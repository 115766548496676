import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

var clock = new THREE.Clock();
var root;
var mixer;
var scene = new THREE.Scene();
var camera = new THREE.PerspectiveCamera(
  75,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);
camera.position.z = 6;
camera.position.y = 2;
camera.position.x = 0;

var renderer = new THREE.WebGLRenderer({ alpha: true });
var container = document.getElementById("canvas");
renderer.setSize(container.offsetWidth, container.offsetHeight);
container.appendChild(renderer.domElement);

const loader = new GLTFLoader();
loader.load(
  "assets/walk-glitch.glb",
  function (glb) {
    console.log(glb);
    root = glb.scene;
    root.scale.set(0.075, 0.075, 0.075);
    root.rotation.x += 0.5;
    mixer = new THREE.AnimationMixer(root);
    mixer.clipAction(glb.animations[0]).play();
    scene.add(root);
  },
  function (xhr) {
    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
  },
  function (error) {
    console.log("ERROR", error);
  }
);

const light = new THREE.DirectionalLight(0xffffff, 1);
light.position.set(-5, 2, 10);
scene.add(light);

var animate = function () {
  requestAnimationFrame(animate);
  if (mixer) mixer.update(clock.getDelta() / 1.5);
  if (root == undefined) {
    return;
  }
  root.rotation.y += 0.01;
  renderer.render(scene, camera);
};

animate();

window.addEventListener("resize", onWindowResize, false);

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(container.offsetWidth, container.offsetHeight);
}
